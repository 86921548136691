import React, {useState} from "react";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import FavoriteBorderRoundedIcon from "@material-ui/icons/FavoriteBorderRounded";
import FavoriteIcon from '@material-ui/icons/Favorite';
import { Grid, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Vector from "../../Assets/Common/Vector.svg";
import TopImg from "../../Assets/Home/top.jpg";
import "./menuDetail.scss";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {addToCartUnsafe, resetCart} from "../../actions";
import userApi from "../../Api/userApi";
import {toast} from "react-toastify";
import {cart} from "../../Models/Cart";
import ModalResetBasket from "../ModalLogin/ModalResetBasket";
import {displayPrice} from "../../helpers/price";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const MenuDetail = ({favorites, setIsShowDetail, updateChange}) => {
  const classes = useStyles();
  const carts = useSelector((state) => state.cartReducer.cart);
  const { hash } = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const food = useSelector((state) =>
    state.foodReducer.foods.filter(
      (item) => item.id === Number(hash.split("#")[1])
    )
  );
  const foodFavorite = favorites?.find((item) => (
    item.food_id === Number(hash.split("#")[1])
  ));
  const userReducer = useSelector((state) => state.userReducer);
  const foodReviews = useSelector((state) => state.reviewReducer.food_reviews);
  const [quantity, setQuantity] = useState(1);
  const [openModalResetBasket, setOpenModalResetBasket] = useState(false);

  const onAddToCart = (product, qty, reset) => {
    if(cart.canAddToBasket(carts, product)){
      AddToCart(product, qty, reset);
    }
    else{
      setOpenModalResetBasket(true);
    }
  };

  const AddToCart = (product, qty, reset) => {
    return cart.addToCart(userReducer, product, qty, reset).then((response) => {
      toast.success("Dodano do koszyka.");
      cart.getCart(userReducer).then((response)=>{
        dispatch(resetCart());
        response.data.map((item)=>{
          dispatch(addToCartUnsafe(item))
        })
      }).catch((e)=>{
        console.log(e);
      });
    }).catch(() => {
      toast.error("Coś poszło nie tak.");
    });
  }

  const addFavorites = (id) => {
    const data = {
      food_id: id,
      user_id: userReducer.id
    };
    if(userReducer.api_token){
      userApi.addFavorites(data, {
        api_token: userReducer.api_token
      }).then(() => {
        toast.success("Dodano do ulubionych.");
        updateChange();
      });
    }
    else{
      toast.error("Aby dodać produkt do ulubionych musisz być zalogowany.");
    }
  }

  const removeFavorite = (id) => {
    userApi.removeFavorites(id,{
      api_token: userReducer.api_token
    }).then(() => {
      toast.success("Usunięto z ulubionych.");
    }).finally(()=>{
      updateChange();
    })
  }

  return (
    <div className="menu-detail">
      {food.length > 0 && carts.length > 0 && (
          <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openModalResetBasket}
              onClose={() => setOpenModalResetBasket(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
          >
            <Fade in={openModalResetBasket}>
              <ModalResetBasket
                  setOpenModal={setOpenModalResetBasket}
                  food={food[0]}
                  cart={carts[0]}
                  quantity={quantity}
                  addToCart={AddToCart}
              />
            </Fade>
          </Modal>
      )}

      <p onClick={()=>{setIsShowDetail(false)}}>{'< Wróć'}</p>
      {food.length ? (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <h3 className="restaurant__title">{food[0].name}</h3>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                className="box"
              >
                <div className="area-number">
                  <p>IIość</p>
                  <div className="d-flex" style={{ alignItems: "center" }}>
                    <button
                      className="d-flex"
                      disabled={quantity <= 1}
                      onClick={() => setQuantity((prev) => prev - 1)}
                    >
                      <RemoveCircleOutlineRoundedIcon />
                    </button>
                    <span>{quantity}</span>
                    <button
                      className="d-flex"
                      onClick={() => setQuantity((prev) => prev + 1)}
                    >
                      <AddCircleOutlineRoundedIcon />
                    </button>
                  </div>
                </div>
                <p
                  className="area-price"
                  onClick={() =>
                      onAddToCart(food[0],quantity, 0)
                  }
                >
                  <span>Dodaj do koszyka</span>
                  <span>{displayPrice(food[0].price)} PLN</span>
                </p>
                {foodFavorite === undefined ? (
                    <button className="area-heart d-flex" onClick={()=>{
                      addFavorites(food[0]['id'])
                    }}>
                      <FavoriteBorderRoundedIcon />
                    </button>
                ):(
                    <button className="area-heart d-flex" onClick={()=> {
                      removeFavorite(foodFavorite.id);
                    }}>
                      <FavoriteIcon />
                    </button>
                )}
              </Box>
              <p
                className="area-desc"
                dangerouslySetInnerHTML={{ __html: food[0].description }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                src={
                  food[0].media.length
                    ? food[0].media[0].url
                      ? food[0].media[0].url.split(".pl").join(".pl/")
                      : TopImg
                    : TopImg
                }
                width="100%"
                className="restaurant__img"
                alt=""
              />
            </Grid>
          </Grid>

          <div className="area-skladniki">
            <div className="d-flex">
              <img src={Vector} className="mr10" alt="star" />
              <Typography className="top">Skladniki</Typography>
            </div>
            <p
              className="area-desc"
              dangerouslySetInnerHTML={{
                __html: food[0].ingredients && food[0].ingredients,
              }}
            />
          </div>
          <div>


          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default MenuDetail;
