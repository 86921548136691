import React from "react";
import { Grid, Typography } from "@material-ui/core";

import MenuCard from "../../Components/MenuRestaurant/MenuCard";
// import TrendContainer from "../../Components/TrendContainer";
// import Trending from "../../Assets/Home/trending.svg";
import Menu from "../../Assets/Restaurant/menu.svg";
import Food from "../../Assets/Restaurant/food.png";
// import TopImg from "../../Assets/Home/top.jpg";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// const TRENDY = [
//   {
//     imgSrc: TopImg,
//     title: "Makaron",
//     description: "Restauracja",
//     price: "11.50 PLN",
//   },
//   {
//     imgSrc: TopImg,
//     title: "Makaron1",
//     description: "Restauracja1",
//     price: "11.50 PLN",
//   },
//   {
//     imgSrc: TopImg,
//     title: "Makaron2",
//     description: "Restauracja2",
//     price: "11.50 PLN",
//   },
//   {
//     imgSrc: TopImg,
//     title: "Makaron3",
//     description: "Restauracja3",
//     price: "11.50 PLN",
//   },
//   {
//     imgSrc: TopImg,
//     title: "Makaron4",
//     description: "Restauracja4",
//     price: "11.50 PLN",
//   },
//   {
//     imgSrc: TopImg,
//     title: "Makaron5",
//     description: "Restauracja5",
//     price: "11.50 PLN",
//   },
// ];

const MenuList = () => {
  const { id } = useParams();
  const foods = useSelector((state) => state.foodReducer.foods);
  return (
    <>
      {/* <div className="d-flex mb30">
        <img src={Trending} className="mr5" alt="" />
        <Typography className="top">Trendy tygodnia</Typography>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          margin: "0 -10px",
          overflowX: "auto",
          paddingBottom: 5,
        }}
      >
        {TRENDY.map((item, index) => (
          <div key={index} style={{ maxWidth: "147px", padding: "0 10px" }}>
            <TrendContainer
              imgSrc={item.imgSrc}
              title={item.title}
              description={item.description}
              price={item.price}
            />
          </div>
        ))}
      </div> */}
      <div className="d-flex mt40 mb30">
        <img src={Menu} className="mr5" alt="" />
        <Typography component={"span"} className="top">
          Menu
        </Typography>
      </div>
      <Grid container spacing={5}>
        {foods
          .filter((item) => item.restaurant_id === Number(id))
          .map((food) => (
            <Grid item key={food.id} xs={12} sm={6} md={4}>
              <MenuCard
                imgSrc={
                  food.media.length
                    ? food.media[0].url
                      ? food.media[0].url.split(".pl").join(".pl/")
                      : Food
                    : Food
                }
                name={food.name}
                desc={food.description}
                price={food.price}
                restaurantId={food.restaurant_id}
                id={food.id}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default MenuList;
