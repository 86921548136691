import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
// Components
import CardReview from "../../Components/CardReview";
import { useSelector } from "react-redux";

// Assets
import TopImage from "../../Assets/Home/top.jpg";
import Check from "../../Assets/Home/check.svg";
import AppStore from "../../Assets/Home/appstore.png";
import GooglePlay from "../../Assets/Home/googleplay.png";
import App from "../../Assets/Home/app.svg";
import Star from "../../Assets/Home/star.svg";

// Styles
import "./styles.scss";
import styled from "styled-components";

const TypographyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const AppsWrapper = styled.div`
  @media(max-width: 600px){
    flex-direction: column;
    width: 100%;
  }
`;

const Home = () => {
  const history = useHistory();
  const restaurantReviews = useSelector(
    (state) => state.reviewReducer.restaurant_reviews
  );
  const restaurants = useSelector((state) => state.data.restaurants);

  return (
    <>
      {/*<div className="searchArea">*/}
      {/*  <SearchBox*/}
      {/*    title="Poszukaj Restauracji,"*/}
      {/*    subtitle="z której chcesz zamówić jedzenie"*/}
      {/*  />*/}
      {/*</div>*/}
      <div className="reviewArea">
        <div className="container">
          <div className="d-flex mb30">
            <img src={Star} className="mr10" alt="star rate" />
            <TypographyWrapper>
              <Typography className="top">TOP Sprzedawcy</Typography>
              <Typography className="top pointer" onClick={()=>{
                history.push(`/restaurants?search=`);
              }}>Wszystkie ></Typography>
            </TypographyWrapper>
          </div>
          <Grid container spacing={3}>
            {restaurants
              .filter((item) => item.rate !== null)
              .sort((a, b) =>
                b.rate.localeCompare(a.rate, undefined, {
                  numeric: true,
                  sensitivity: "base",
                })
              )
              .slice(0, 4)
              .map((restaurant) => (
                <Grid key={restaurant.id} item lg={3} md={4} sm={6} xs={12}>
                  <CardReview
                    imgSrc={
                      restaurant.media.length
                        ? restaurant.media[0].url
                          ? restaurant.media[0].url.split(".pl").join(".pl/")
                          : TopImage
                        : TopImage
                    }
                    name={restaurant.name}
                    id={restaurant.id}
                    rate={restaurant.rate}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
      </div>
      <div className="appArea">
        <Grid container spacing={3} className="mainApp">
          <Grid item lg={7} md={7} sm={7} xs={12}>
            <img src={Check} className="checkImg" alt="Check" />
            <Typography className="appText1">
              Skorzystaj z intuicyjnej
            </Typography>
            <Typography className="appText2">aplikacji mobilnej</Typography>
            <AppsWrapper className="d-flex link_app">
              <div className="d-flex">
                <a target="_blank" href="https://apps.apple.com/us/app/gastro-towar/id1551620076">
                  <img src={AppStore} className="mr20" alt="App Store" />
                </a>
              </div>
              <div className="d-flex">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.gastro.towar&gl=PL">
                  <img src={GooglePlay} alt="Google play" />
                </a>
              </div>
            </AppsWrapper>
          </Grid>
          <Grid item lg={5} md={5} sm={5} xs={12} className="">
            <img src={App} alt="App" />
          </Grid>
        </Grid>
      </div>

      {/*<div className="Ostatnie">*/}
      {/*  <div className="container">*/}
      {/*    <div className="d-flex mt40 mb20">*/}
      {/*      <img src={Note} className="mr10" alt="star" />*/}
      {/*      <Typography className="top">Ostatnie opinie</Typography>*/}
      {/*    </div>*/}
      {/*    <Grid container spacing={4}>*/}
      {/*      {restaurantReviews*/}
      {/*        ?.filter((item) => item.rate)*/}
      {/*        .sort((a, b) =>*/}
      {/*          b.rate.toString().localeCompare(a.rate.toString(), undefined, {*/}
      {/*            numeric: true,*/}
      {/*            sensitivity: "base",*/}
      {/*          })*/}
      {/*        )*/}
      {/*        .slice(0, 3)*/}
      {/*        .map((review) => (*/}
      {/*          <Grid key={review.id} item lg={4} md={4} sm={6} xs={12}>*/}
      {/*            <Opinion*/}
      {/*              avatar={review.user.media.length*/}
      {/*                ? review.user.media[0].url*/}
      {/*                  ? review.user.media[0].url.split(".pl").join(".pl/")*/}
      {/*                  : TopImage*/}
      {/*                : TopImage}*/}
      {/*              name={review.user.name}*/}
      {/*              content={review.review}*/}
      {/*              rate={review.rate}*/}
      {/*              restaurantId={review.restaurant_id}*/}
      {/*            />*/}
      {/*          </Grid>*/}
      {/*        ))}*/}
      {/*    </Grid>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
};

export default Home;
