import React from "react";
import { useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import userApi from "../../Api/userApi";

// Assets
import Logo from "../../Assets/Common/logo.png";
import Facebook from "../../Assets/Common/facebook.svg";
import Instagram from "../../Assets/Common/instagram.svg";

// Styles
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions";
import GrayIcon from "../Icons/GrayIcon";

const totalQuantity = (arr) => {
  return arr.reduce((total, item) => {
    return total + parseInt(item.qty);
  }, 0);
};

const Header = () => {
  const dispatch = useDispatch();
  const userReducer = useSelector((state) => state.userReducer);
  const carts = useSelector((state) => state.cartReducer.cart);
  const listPathBigHeader = ["/sign", "/", '/restaurants'];
  const location = useLocation();
  const bigHeader = listPathBigHeader.indexOf(location.pathname) !== -1;

  const onLogOut = async () => {
    try {
      const user = await userApi.logout({ api_token: userReducer.api_token });
      if (user.data) {
        toast.success("Wylogowanie się powiodło.");
        dispatch(logout());
      } else {
        toast.error("Wylogowanie błąd.");
      }
    } catch (error) {
      dispatch(logout());
      throw error;
    }
  };

  return (
    <div className={`header ${!bigHeader && "small"}`}>
      <div className="container">
        <Link to="/">
          <img src={Logo} className="logo" alt="Logo" />
        </Link>
        <div className="social">
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://www.facebook.com/gastrotowarpl" target="_blank">
            <GrayIcon src={Facebook} className="facebook" alt="facebook" />
          </a>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://www.instagram.com/gastrotowarpl" target="_blank">
            <GrayIcon src={Instagram} className="instagram" alt="facebook" />
          </a>
          <div className="buttons">
            {userReducer.name ? (
              <>
                <p onClick={onLogOut} className="btn1">
                  Wyloguj
                </p>
                <Link to="/customer" className="btn2">
                  {userReducer.name}
                </Link>
              </>
            ) : (
              <Link to="/sign" className="btn1">
                Logowanie
              </Link>
            )}
            {(userReducer.api_token !== "" || userReducer.guest_user_id != null)&& (
              <Link to="/cart" className="btn2">
                Koszyk ({totalQuantity(carts)})
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
