import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import ArrowForward from "@material-ui/icons/ArrowForward";

import SearchIcon from "@material-ui/icons/Search";

// Assets
import MapPicker from "../../Assets/Home/map.svg";

// Styles
import "./styles.scss";
import {useHistory, useLocation} from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';

const BlackSwitch = withStyles({
  switchBase: {
    color: '#000000',
    '&$checked': {
      color: '#000000',
    },
    '&$checked + $track': {
      backgroundColor: '#000000',
    },
  },
  checked: {},
  track: {},
})(Switch);

const ButtonsWrapper = styled.div`
  display: flex;
`;

const SearchBox = (props) => {
  const { title, subtitle } = props;
  const location = useLocation();
  const history = useHistory();
  const [keySearch, setKeySearch] = useState("");
  const [delivery, setDelivery] = useState(true);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if(query.has('delivery')){
      setDelivery(query.get('delivery') === 'true');
    }
    if(query.has('open')){
      setOpen(query.get('open') === 'true');
    }
    if(query.has('search')){
      setKeySearch(query.get('search'));
    }
  }, []);

  const onSubmitForm = (e) => {
    e.preventDefault();
    history.push(`/restaurants?search=${keySearch.trim()}&open=${open}&delivery=${delivery}`);
  };

  const toggleDelivery = (e) => {
    setDelivery(e.target.checked);
  }

  const toggleOpen = (e) => {
    setOpen(e.target.checked);
  }

  return (
    <div className="container">
      <Grid
        item={true}
        sm={12}
        xs={6}
        lg={6}
        style={{ margin: "auto" }}
        className="searchBox"
      >
        <Typography className="title">{title}</Typography>
        <Typography className="subTitle">{subtitle}</Typography>
        <form onSubmit={onSubmitForm}>
          <div className="search">
            <div className="searchIcon">
              <SearchIcon classes={{ root: "searchColor" }} />
            </div>
            <InputBase
              placeholder="Szukaj restauracji ..."
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                setKeySearch(e.target.value);
              }}
              value={keySearch}
              classes={{
                root: "inputSearch",
                input: "inputInput",
              }}
              name="search"
            />
          </div>
          <div className="button">
            <FormControlLabel
                className="switchInput"
                control={<BlackSwitch checked={delivery} onChange={toggleDelivery} />}
                label={delivery ? "Dostawa" : "Odbiór"}
            />

            <FormControlLabel
                className="switchInput"
                control={<BlackSwitch checked={open} onChange={toggleOpen} />}
                label={open ? "Wszystkie" :"Otwarte"}
            />
          </div>
          <ButtonsWrapper>
            <div className="button">
              <Button className="searchBtn" type="submit">
                SZUKAJ
              </Button>
              <div className="arrow">
                <ArrowForward
                    classes={{
                      root: "arrowRight",
                    }}
                />
              </div>
            </div>

            <div className="button">
              <div className="mapPicker">
                <img src={MapPicker} alt="map_picker" />
              </div>
              <Button className="searchBtn" type="button" onClick={() => {
                history.push(`/maps?search=${keySearch.trim()}&open=${open}&delivery=${delivery}`);
              }}>
                lub szukaj po mapie
              </Button>
              <div className="arrow">
                <ArrowForward
                    classes={{
                      root: "arrowRight",
                    }}
                />
              </div>
            </div>
          </ButtonsWrapper>
        </form>
      </Grid>
    </div>
  );
};

export default SearchBox;
