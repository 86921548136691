import cashLogo from "../Assets/Cart/money.svg";
import {orderApi} from "../Api/orderApi";
import {addressApi} from "../Api/addressApi";
import {toast} from "react-toastify";

export const payment = {
    paymentsCash: () => {
        return [
            {
                id: "cod",
                name: "Gotówka",
                description: "Zapłacę gotówką przy odbiorze",
                route: "/CashOnDelivery",
                logo: cashLogo,
                method: "Cash on Delivery"
            },
            {
                id: "codc",
                name: "Karta",
                description: "Zapłacę kartą przy odbiorze",
                route: "/CashOnDelivery",
                logo: null,
                method: "Cash on Delivery"
            }
        ];
    },
    paymentPickup: () => {
        return [
            {
                id: "pop",
                name: "Płatność przy odbiorze",
                description: "Kliknij aby zapłacić przy odbiorze",
                route: "/PayOnPickup",
                logo: cashLogo,
                method: 'Pay on Pickup'
            }
        ];
    },
    pay: (user, shippingPrice, carts, payment, address, successPayment) => {
        const foods = carts.map((item) => {
            return {
                id:item.id,
                price:item.food.price,
                quantity:item.quantity,
                food_id:item.food_id
            }
        });
        if(payment.id === "pop"){
            const data = {
                id: null,
                user_id: user.id,
                order_status_id: 1,
                tax: 0.0,
                delivery_fee: 0.0,
                foods: foods,
                payment: {
                    id: null,
                    status: null,
                    method: payment.method
                }
            };

            return orderApi.addOrder(data, {
                api_token: user.api_token
            }).then((response) => {
                successPayment(response);
            });
        }
        else{
            addressApi.addAddress({
                user_id: user.id,
                address: `${address.address} ${address.city}`,
                description: `${address.name} ${address.email} ${address.phone}`,
            },{api_token: user.api_token}).then((response) => {
                if(response.success){
                    const {id:deliveryId} = response.data;
                    const data = {
                        id: null,
                        user_id: user.id,
                        order_status_id: 1,
                        tax: 0.0,
                        delivery_fee: shippingPrice,
                        foods: foods,
                        payment: {
                            id: null,
                            status: null,
                            method: payment.method
                        },
                        delivery_address_id:deliveryId
                    };
                    return orderApi.addOrder(data, {
                        api_token: user.api_token
                    }).then((response) => {
                        successPayment(response);
                    });
                }
                else{
                    toast.error(response.message);
                }
            })
        }
    }
};
