import {
  FETCH_ALL_FOOD,
  FETCH_ALL_RESTAURANT,
  FETCH_ALL_FOOD_REVIEW,
  FETCH_ALL_RESTAURANT_REVIEW,
  FETCH_SINGLE_RESTAURANT,
  LOG_OUT,
  SET_USER,
  FETCH_FAQS,
  FETCH_FAQ_CATEGORIES,
  FETCH_REGULATIONS,
  FETCH_PRIVACY_POLICY,
  FETCH_COOKIE_POLICY,
  FETCH_BENEFIT,
  FETCH_O_NAS,
  FETCH_RESTAURANT_ZONE,
  FETCH_USER_ORDERS,
  FETCH_USER_FAVORITES,
  FETCH_USER_SETTINGS,
  FETCH_USER_NOTIFICATIONS,
  REMOVE_FROM_CART,
  ADD_TO_CART,
  SET_USER_LOCATION, RESET_CART, INCREMENT_QTY, SET_USER_QUEST_ID, FETCH_CONTACT,
} from "../constants/ActionTypes";

export const fetchAllRestaurant = (restaurants) => ({
  type: FETCH_ALL_RESTAURANT,
  payload: restaurants,
});

export const fetchSingleRestaurant = (restaurant) => ({
  type: FETCH_SINGLE_RESTAURANT,
  payload: restaurant,
});

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});
export const logout = () => ({ type: LOG_OUT });
export const getUserOrders = (orders) => ({
  type: FETCH_USER_ORDERS,
  payload: orders,
});
export const getUserFavorites = (favorites) => ({
  type: FETCH_USER_FAVORITES,
  payload: favorites,
});
export const getUserSettings = (settings) => ({
  type: FETCH_USER_SETTINGS,
  payload: settings,
});
export const getUserNotifications = (notifications) => ({
  type: FETCH_USER_NOTIFICATIONS,
  payload: notifications,
});
export const setUserLocation = (location) => ({
  type: SET_USER_LOCATION,
  payload: location,
});

export const setUserQuestId = () => ({
  type: SET_USER_QUEST_ID
});

export const getAllFoodReview = (reviews) => ({
  type: FETCH_ALL_FOOD_REVIEW,
  payload: reviews,
});

export const getAllRestaurantReview = (reviews) => ({
  type: FETCH_ALL_RESTAURANT_REVIEW,
  payload: reviews,
});

export const getAllFood = (foods) => ({
  type: FETCH_ALL_FOOD,
  payload: foods,
});

export const getFaqs = (faqs) => ({
  type: FETCH_FAQS,
  payload: faqs,
});

export const getFaqCategories = (categories) => ({
  type: FETCH_FAQ_CATEGORIES,
  payload: categories,
});

export const getRegulations = (regulations) => ({
  type: FETCH_REGULATIONS,
  payload: regulations,
});

export const getPrivacyPolicy = (privacy) => ({
  type: FETCH_PRIVACY_POLICY,
  payload: privacy,
});

export const getCookiePolicy = (cookie) => ({
  type: FETCH_COOKIE_POLICY,
  payload: cookie,
});
export const getContact = (contact) => ({
  type: FETCH_CONTACT,
  payload: contact,
});

export const getBenefit = (benefit) => ({
  type: FETCH_BENEFIT,
  payload: benefit,
});

export const getONas = (oNas) => ({
  type: FETCH_O_NAS,
  payload: oNas,
});

export const getRestaurantZone = (zone) => ({
  type: FETCH_RESTAURANT_ZONE,
  payload: zone,
});

export const addToCartUnsafe = (product) => ({
  type: ADD_TO_CART,
  product
});

export const resetCart = () => ({
  type: RESET_CART
});

export const removeFromCart = (cartId) => ({
  type: REMOVE_FROM_CART,
  cartId,
});
export const incrementQty = (cartId, qty) => ({
  type: INCREMENT_QTY,
  cartId,
  qty
});
