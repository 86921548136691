import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import styled from 'styled-components';

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const TitleWrapper = styled.div`
  background-color: #31A7EE;
  padding: 10px 20px;
  margin-bottom: 20px;
  h2{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #fff !important;
    margin: 0;
  }
`;

const ContentWrapper = styled.div`
  padding: 10px 20px;
  font-size: 14px;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export default function FAQ() {
  const faqCategories = useSelector((state) => state.faqReducer.faq_categories);
  const faqs = useSelector((state) => state.faqReducer.faqs);
  const [selectFaqCate, setSelectFaqCate] = useState(0);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const regex = /(<([^>]+)>)/ig;

  const handleChange = (event, newValue) => {
    console.log(newValue);
    console.log(event.target);
    setValue(newValue);
  };

  useEffect(() => {
    if (faqCategories.length) {
      setSelectFaqCate(faqCategories[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div className={classes.root}>
        <div className={classes.demo1}>
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
          >
            {faqCategories.map((cate) => (
              <AntTab
                label={cate.name}
                key={cate.id}
                onClick={() => setSelectFaqCate(cate.id)}
              />
            ))}
          </AntTabs>
          {faqCategories.map((cate, index) => (
            <TabPanel value={value} index={index}>
              {faqs
                .filter((item) => item.faq_category_id === selectFaqCate)
                .map((faq) => (
                  <div>
                    <TitleWrapper>
                      <h2>{faq.question.replace(regex, '')}</h2>
                    </TitleWrapper>
                    <ContentWrapper>
                      <span>{faq.answer.replace(regex, '')}</span>
                    </ContentWrapper>
                  </div>
                ))}
            </TabPanel>
          ))}
        </div>
      </div>
    </div>
  );
}
