import React from "react";
import Breadcrumb from "../../Components/Breadcrumbs";

import "./style.scss";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";

const Join = () => {
  return (
    <div className="onas">
      <div className="container">
        <Breadcrumb title="Dołącz" />
        <div className="onas--content">
            lorem ipsum
        </div>
          <div>
              <Link href="https://panel.gastrotowar.pl/register" className="link">
                  <Button className="btn">
                      Dołącz
                  </Button>
              </Link>
          </div>
      </div>
    </div>
  );
};

export default Join;
