import axiosClient from "./axiosClient";

const SocialApi = {
    facebook: (params) => {
        const url = "/social/facebook/redirect";
        return axiosClient.post(url,{},{ params });
    },

    facebookLogin: (token, params) => {
        const url = `/social/facebook/${token}`;
        return axiosClient.post(url,{},{ params });
    },
    google: (params) => {
        const url = "/social/google/redirect";
        return axiosClient.post(url,{},{ params });
    },

    googleLogin: (token, params) => {
        const url = `/social/google/${token}`;
        return axiosClient.post(url,{},{ params });
    }
}

export default SocialApi;
