/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {Formik} from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import userApi from "../../Api/userApi";
import RedirectPage from "../../Components/Redirect";
import { useDispatch, useSelector } from "react-redux";
// Assets
import Google from "../../Assets/Sign/google.svg";
import Facebook from "../../Assets/Sign/facebook.svg";
import styled from 'styled-components';

// Styles
import "./styles.scss";
import { setUser } from "../../actions";
import {cart} from "../../Models/Cart";
import SocialApi from "../../Api/socialApi";

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email("Niepoprawny format maila.")
    .required("Email jest wymagany."),
  password: Yup.string()
    .min(6, "Hasło musi mieć minimum 6 znaków.")
    .required("Hasło jest wymagane."),
});

const SignUpSchema = Yup.object().shape({
  name: Yup.string().required("Imię jest wymagane."),
  email: Yup.string()
    .email("Niepoprawny format maila.")
    .required("Email jest wymagany."),
  password: Yup.string()
    .min(6, "Hasło musi mieć minimum 6 znaków.")
    .required("Hasło jest wymagane."),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password")], "Hasła nie pasują.")
    .required("Hasło jest wymagane"),
});

const ImgSocial = styled.img`
  cursor: pointer;
`;

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const carts = useSelector((state) => state.cartReducer.cart);
  const user = useSelector((state) => state.userReducer);
  const [showPassword, setShowPassword] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingSignUp, setLoadingSignUp] = useState(false);
  const [showPasswordSignUp, setShowPasswordSignUp] = useState(false);
  const [showPasswordSignUpConfirm, setShowPasswordSignUpConfirm] = useState(
    false
  );

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onLogin = async (values) => {
    setLoadingLogin(true);
    try {
      const user = await userApi.login(values);
      setLoadingLogin(false);
      if (user.data) {
        toast.success("Logowanie się powiodło.");
        let firstElementReset = 1;
        carts.forEach((item) => {
          cart.addToCart(user.data,item.food,item.quantity,firstElementReset).then(() => {
          });
          firstElementReset = false;
        });
        dispatch(
          setUser({
            email: user.data.email,
            name: user.data.name,
            api_token: user.data.api_token,
            id: user.data.id,
            phone: user.settings?.custom_fields?.bio?.value,
            address: user.settings?.custom_fields?.bio?.value,
            bio: user.settings?.custom_fields?.bio?.value,
          })
        );
        history.push("/");
      } else {
        toast.error("Niepoprawny email lub hasło.");
      }
    } catch (error) {
      toast.error("Potwierdź adres email.");
      setLoadingLogin(false);
      throw error;
    }
  };

  const onRegister = async (values, {resetForm}) => {
    setLoadingSignUp(true);
    try {
      const user = await userApi.register(values);
      setLoadingSignUp(false);
      if (user.success) {
        resetForm();
        toast.success("Rejestracja się powiodła.");
      } else {
        toast.error("Email istnieje w bazie.");
      }
    } catch (error) {
      setLoadingSignUp(false);
      toast.error("Użytkownik o takim Email istnieje już w bazie.");
      throw error;
    }
  };

  if (user.name) {
    return <RedirectPage to="/" />;
  }
  return (
    <div className="sign">
      <div className="container">
        <div className="sign__warp">
          <div className="sign__warp__form sign__warp--left">
            <Typography className="LoginFont">Logowanie</Typography>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={SignInSchema}
              onSubmit={onLogin}
              className="alignCenter"
            >
              {({
                handleSubmit,
                errors,
                values,
                touched,
                handleBlur,
                handleChange,
              }) => (
                <>
                  <TextField
                    className="textInput"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="E-mail"
                    name="email"
                    autoComplete="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.email && touched.email && errors.email}
                  />
                  <FormControl
                    className="textInput"
                    variant="outlined"
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Hasło
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange}
                      name="password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      onBlur={handleBlur}
                      labelWidth={70}
                    />
                    <FormHelperText>
                      {errors.password && touched.password && errors.password}
                    </FormHelperText>
                  </FormControl>

                  <Button
                    onClick={handleSubmit}
                    type="small"
                    variant="contained"
                    className="btn__sign"
                  >
                    {loadingLogin && <CircularProgress size={18} />}
                    {!loadingLogin && "ZALOGUJ"}
                  </Button>
                </>
              )}
            </Formik>

            <Typography className="social__login">lub przez</Typography>
            <div className="wrap__social">
              <div className="wrap__social__contain">
                <ImgSocial src={Facebook} alt="" onClick={() => {
                  SocialApi.facebook().then(({data}) => {
                    window.location = data.url;
                  }).catch((e) => {
                    console.log(e);
                    toast.error("Błąd - spróbuj ponownie");
                  });
                }}
                />
                <ImgSocial src={Google} alt="" onClick={() => {
                  SocialApi.google().then(({data}) => {
                    window.location = data.url;
                  }).catch((e) => {
                    console.log(e);
                    toast.error("Błąd - spróbuj ponownie");
                  });
                }}
                />
              </div>
            </div>

            <Typography className="forget">
              <a href="/reset">Zapomniałeś hasła?</a>
            </Typography>
          </div>
          <div className="sign__warp__form sign__warp--right">
            <Typography className="LoginFont">Rejestracja</Typography>
            <Formik
              initialValues={{
                name: "",
                email: "",
                password: "",
                password_confirmation: "",
              }}
              validationSchema={SignUpSchema}
              onSubmit={onRegister}
              className="alignCenter"
            >
              {({
                handleSubmit,
                errors,
                values,
                touched,
                handleBlur,
                handleChange,
              }) => (
                <>
                  <FormControl
                      className="textInput"
                      variant="outlined"
                      fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password1">
                      Imię
                    </InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password1"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        name="name"
                        onBlur={handleBlur}
                        labelWidth={70}
                    />
                    <FormHelperText>
                      {errors.name && touched.name && errors.name}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                      className="textInput"
                      variant="outlined"
                      fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password1">
                      E-mail
                    </InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password1"
                        type="text"
                        value={values.email}
                        onChange={handleChange}
                        name="email"
                        onBlur={handleBlur}
                        labelWidth={70}
                        autoComplete="email"
                    />
                    <FormHelperText>
                      {errors.email && touched.email && errors.email}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    className="textInput"
                    variant="outlined"
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password1">
                      Hasło
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password1"
                      type={showPasswordSignUp ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange}
                      name="password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowPasswordSignUp(!showPasswordSignUp)
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPasswordSignUp ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      onBlur={handleBlur}
                      labelWidth={70}
                    />
                    <FormHelperText>
                      {errors.password && touched.password && errors.password}
                    </FormHelperText>
                  </FormControl>

                  <FormControl
                    className="textInput"
                    variant="outlined"
                    style={{ marginTop: "42px" }}
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password2">
                      Potwórz hasło
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password2"
                      type={showPasswordSignUpConfirm ? "text" : "password"}
                      value={values.password_confirmation}
                      onChange={handleChange}
                      name="password_confirmation"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowPasswordSignUpConfirm(
                                !showPasswordSignUpConfirm
                              )
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPasswordSignUpConfirm ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      onBlur={handleBlur}
                      labelWidth={70}
                    />
                    <FormHelperText>
                      {errors.password_confirmation &&
                        touched.password_confirmation &&
                        errors.password_confirmation}
                    </FormHelperText>
                  </FormControl>
                  <br/>
                  <Button
                    onClick={handleSubmit}
                    type="smalll"
                    variant="contained"
                    className="btn__sign btn__register"
                  >
                    {loadingSignUp && <CircularProgress size={18} />}
                    {!loadingSignUp && "ZAREJESTRUJ"}
                  </Button>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
