import axiosClient from "./axiosClient";

const faqApi = {
  getFaqs: (params) => {
    const url = "/faqs";
    return axiosClient.get(url, { params });
  },
  getFaqCategories: (params) => {
    const url = "/faq_categories";
    return axiosClient.get(url, {params})
  } 
};

export default faqApi;