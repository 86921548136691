import React from "react";
import Breadcrumb from "../../Components/Breadcrumbs";
import _ from "lodash";
import { useSelector } from "react-redux";

import "./style.scss";

const Contact = () => {
  const contact = useSelector((state) => state.otherReducer.contact);
  return (
    <div className="cookie_policy">
      <div className="container">
        <Breadcrumb title="Kontakt" />
        {!_.isEmpty(contact) && (
          <div className="cookie_policy--content">
            <div dangerouslySetInnerHTML={{ __html: contact.question }} />
            <div dangerouslySetInnerHTML={{ __html: contact.answer }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;
