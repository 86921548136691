/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import userApi from "../../Api/userApi";
import {useDispatch, useSelector} from "react-redux";
// Assets

// Styles
import "./styles.scss";
import { setUser } from "../../actions";
import {cart} from "../../Models/Cart";

const SignInSchema = Yup.object().shape({
  email: Yup.string()
      .email("Niepoprawny format maila.")
      .required("Email jest wymagany."),
  password: Yup.string()
      .min(6, "Hasło musi mieć minimum 6 znaków.")
      .required("Hasło jest wymagane."),
});

const ModalLogin = ({ setOpenModal }) => {
  const dispatch = useDispatch();
  const carts = useSelector((state) => state.cartReducer.cart);
  const [showPassword, setShowPassword] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onLogin = async (values) => {
    setLoadingLogin(true);
    try {
      const user = await userApi.login(values);
      setLoadingLogin(false);
      if (user.data) {
        toast.success("Logowanie się powiodło.");
        let firstElementReset = 1;
        carts.forEach((item) => {
          cart.addToCart(user.data,item.food,item.quantity,firstElementReset).then(() => {
          });
          firstElementReset = false;
        });
        dispatch(
            setUser({
              email: user.data.email,
              name: user.data.name,
              api_token: user.data.api_token,
              id: user.data.id,
              phone: user.settings?.custom_fields?.bio?.value,
              address: user.settings?.custom_fields?.bio?.value,
              bio: user.settings?.custom_fields?.bio?.value,
            })
        );
        setOpenModal(false);
      } else {
        toast.error("Niepoprawny email lub hasło.");
      }
    } catch (error) {
      toast.error("Użytkownik o takim Email istnieje już w bazie.");
      setLoadingLogin(false);
      throw error;
    }
  };

  return (
    <div className="sign">
      <div className="container">
        <div className="sign__warp">
          <div className="sign__warp__form sign__warp--left">
            <Typography className="LoginFont">Logowanie</Typography>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={SignInSchema}
              onSubmit={onLogin}
              className="alignCenter"
            >
              {({
                handleSubmit,
                errors,
                values,
                touched,
                handleBlur,
                handleChange,
              }) => (
                <>
                  <TextField
                    className="textInput"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="E-mail"
                    name="email"
                    autoComplete="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.email && touched.email && errors.email}
                  />
                  <FormControl
                    className="textInput"
                    variant="outlined"
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Hasło
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange}
                      name="password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      onBlur={handleBlur}
                      labelWidth={70}
                    />
                    <FormHelperText>
                      {errors.password && touched.password && errors.password}
                    </FormHelperText>
                  </FormControl>

                  <Button
                    onClick={handleSubmit}
                    type="small"
                    variant="contained"
                    className="btn__sign"
                    style={{ marginBottom: 20 }}
                  >
                    {loadingLogin && <CircularProgress size={18} />}
                    {!loadingLogin && "ZALOGUJ"}
                  </Button>
                </>
              )}
            </Formik>

            <div className="forget">
              <Link to="/sign">Zarejestruj się teraz, aby założyć konto</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLogin;
