import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
// import Button from "@material-ui/core/Button";

// Styles
import "./styles.scss";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

const CardReview = (props) => {
  const { id, imgSrc, name } = props;

  return (
    <Link
      to={`/restaurants/${id}`}
      style={{ textDecoration: "none", color: "#000" }}
    >
      <Card className="card_review">
        <CardActionArea>
          <CardMedia image={imgSrc} title={name} className="media" />
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography gutterBottom className="content">
                  {name}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};

export default CardReview;
