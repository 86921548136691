import React from "react";
import {Payment, Bookmark} from "@material-ui/icons";
import {payment} from "../../Models/Payment";
import PaymentAction from "../../Components/Checkout/PaymentAction";

const CartPayment = ({ handleSubmit,setPayment }) => {
  return (
    <div className="cart_payment fade-in">
      <div className="cart_payment--offline">
        <div className="cart_payment--title">
          <Bookmark />
          <span>Płatność przy odbiorze</span>
        </div>
        {payment.paymentsCash().map((item)=> (
            <PaymentAction key={item.id} payment={item} setPayment={setPayment} submit={handleSubmit}/>
        ))}
      </div>
    </div>
  );
};

export default CartPayment;
