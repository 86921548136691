import React from "react";
import { Check } from "@material-ui/icons";

const CartSuccess = ({order}) => {
  return (
    <div className="cart_success fade-in">
      <div className="cart_success--wrapper">
        <div className="cart_success--check">
          <Check />
        </div>
        <div className="cart_success--content">
          <p>Dziękujemy</p>
          <p> za złożenie zamówienia</p>
          <p>{`#${order.id}`}</p>
        </div>
      </div>
    </div>
  );
};

export default CartSuccess;
