import React, {useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {getPrice} from "../../helpers/order";
import restaurantApi from "../../Api/restaurantApi";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ModalLogin from "../../Components/ModalLogin";
import Modal from "@material-ui/core/Modal";
import {makeStyles} from "@material-ui/core/styles";

const totalPrice = (arr, shipping) => {
  return arr.reduce((total, item) => {
    return total + parseFloat(item.sum);
  }, 0) + (shipping ?? 0.0);
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const CartMoney = ({ idPanel, changeTab, formValues }) => {
  const classes = useStyles();
  const carts = useSelector((state) => state.cartReducer.cart);
  const userReducer = useSelector((state) => state.userReducer);
  const [restaurant, setRestaurant] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const canGoToShip = (total) => {
    if(restaurant.closed){
      toast.error("Restauracja jest zamknięta");
      return false;
    }
    if(restaurant.minimum_order_amount > total){
      toast.error(`Minimalna kwota zamówienia to ${restaurant.minimum_order_amount} PLN`);
      return false;
    }
    return true;
  }

  const goToShip = (total) => {
    if(canGoToShip(total)){
      changeTab(1)
    }
  }

  const goToPayTab = () => {
    const {shippingMethod, name, city, phone, address, email} = formValues;
    if(shippingMethod !== "store"){
      if(name !== "" && city !== "" && phone !== "" && address !== "" && email !== ""){
        changeTab(2)
      }
      else{
        toast.error("Uzupełnij wymagane dane");
      }
    }
  }

  useEffect(() => {
    if(carts.length){
      if(restaurant == null || (carts[0]?.food.restaurant_id !== restaurant.id)){
        restaurantApi.getSingle(carts[0]?.food.restaurant_id).then((response) => {
          setRestaurant(response.data);
        });
      }
    }
  }, [restaurant, carts]);

  return (
      <>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModal}
            onClose={() => setOpenModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={openModal}>
            <ModalLogin setOpenModal={setOpenModal} />
          </Fade>
        </Modal>
        <div className="cart_money fade-in">
          <Card>
            <div className="card_money--info">
              <div className="total">
                <span>Razem</span>
                <span className="text-bold">
              {getPrice(totalPrice(carts))}
            </span>
              </div>
              <div className="extra">
                <span>Dostawa</span>
                <span>
                        ustalana indywidualnie
                </span>
              </div>
            </div>
            {carts.length ? (
                idPanel === 0 ? (
                    <Button
                        type="button"
                        className="cardBtn"
                        onClick={() => {
                          if(userReducer.id){
                            goToShip(totalPrice(carts));
                          }
                          else{
                            setOpenModal(true);
                          }
                        }}
                        disabled={!carts.length}
                    >
                      <div className="text_left">
                        <ArrowRightAlt fontSize="default" />
                        <span>Dalej</span>
                      </div>
                      <span>{getPrice(totalPrice(carts))}</span>
                    </Button>
                ) : (
                    <>
                      {idPanel === 1 && formValues.shippingMethod !== "store" && (
                          <Button className="cardBtn" type="button" onClick={() => goToPayTab()}>
                            <div className="text_left">
                              <ShoppingCart fontSize="default"/>
                              <span>Zamawiam i płacę</span>
                            </div>
                            <span>{getPrice(totalPrice(carts))}</span>
                          </Button>
                      )}
                    </>
                )
            ) : (
                ""
            )}
          </Card>
        </div>
      </>
  );
};

export default CartMoney;
