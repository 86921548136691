import axiosClient from "./axiosClient";

const otherApi = {
  getRegulations: (params) => {
    const url = "/faqs/32";
    return axiosClient.get(url, { params });
  },
  getPrivacyPolicy: (params) => {
    const url = "/faqs/36";
    return axiosClient.get(url, { params });
  },
  getCookiePolicy: (params) => {
    const url = "/faqs/37";
    return axiosClient.get(url, { params });
  },
  getContact: (params) => {
    const url = "/faqs/34";
    return axiosClient.get(url, { params });
  },
  getBenefit: (params) => {
    const url = "/faqs/35";
    return axiosClient.get(url, { params });
  },
  getONas: (params) => {
    const url = "/faqs/33";
    return axiosClient.get(url, { params });
  },
  getRestaurantZone: (params) => {
    const url = "/faqs/37";
    return axiosClient.get(url, { params });
  },
  getBrokerageRegulationsRegulations: (params) => {
    const url = "/faqs/38";
    return axiosClient.get(url, { params });
  },
};

export default otherApi;
